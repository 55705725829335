/**
@params {number} seconds
@returns {string} time
@description Converts seconds to time hh:mm:ss time format
*/
export default function secondsToTimeFormat(seconds: number): string {
  let minutes = Math.floor(seconds / 60)
  seconds %= 60
  let hours = Math.floor(minutes / 60)
  minutes %= 60
  const days = Math.floor(hours / 24)
  hours %= 24

  const durationStr = `${days.toString().padStart(2, '0')}:${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`

  return durationStr
}
