import { UserManager } from 'oidc-client-ts'
import type { User, UserManagerSettings } from 'oidc-client-ts'

const settings: UserManagerSettings = {
  authority: $settings.authority,
  automaticSilentRenew: true,
  monitorSession: false,
  client_id: $settings.oidc_client_id,
  redirect_uri: `${window.location.origin}/`,
  post_logout_redirect_uri: `${window.location.origin}/`,
  silent_redirect_uri: `${window.location.origin}/#/auth-silent`,
  response_type: 'code',
  response_mode: 'query',
  loadUserInfo: false,
  scope: 'openid',
}
const userManager = new UserManager(settings)

export default function useAuthentication() {
  const userChangeListeners: Function[] = []

  const notifyUserChanged = (user: User) => {
    for (const userChangeListener of userChangeListeners)
      userChangeListener(user)
  }

  const updateUser = (user: User) => {
    notifyUserChanged(user)
  }

  const registerEvents = () => {
    userManager.events.addUserLoaded((user: User) => {
      updateUser(user)
    })
    userManager.events.addSilentRenewError(() => {
      userManager.signoutRedirect()
    })
  }

  const getUserManager = () => {
    return userManager
  }

  const getUser = () => {
    return userManager.getUser()
  }

  const logout = () => {
    userManager.signoutRedirect()
  }

  const registerUserChangeListener = (callback: Function) => {
    if (typeof callback === 'function') {  
      userChangeListeners.push(callback)
    }
  }

  const unregisterUserChangeListener = (callback: Function) => {
    for (const [index, listener] of userChangeListeners.entries()) {
      if (listener === callback) {
        userChangeListeners.splice(index, 1)
        break
      }
    }
  }

  return {
    userManager,
    getUserManager,
    updateUser,
    getUser,
    logout,
    registerUserChangeListener,
    registerEvents,
    unregisterUserChangeListener,
  }
}
