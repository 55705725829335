<script setup lang="ts">
import urlCleaner from '../utils/helpers/urlCleaner'

const { updateTokenFromUser } = useApiService()
const { getUser, registerEvents, userManager, updateUser, registerUserChangeListener } = useAuthentication()

const isServicesInitiated = ref(false)
const isOnline = ref(true)

const updateOnlineStatus = (newOnlineStatus: boolean) => {
  isOnline.value = newOnlineStatus
}

const initiateAuthenticationFlow = async () => {
  let user = await getUser()

  if (user && !user.expired) {
    updateUser(user)
    return user
  }

  try {
    user = await userManager.signinRedirectCallback()

    urlCleaner()

    return user
  }
  catch (error) {
    userManager.signinRedirect()
  }
}

const initiateServices = async () => {
  registerEvents()
  const user = await initiateAuthenticationFlow()
  updateTokenFromUser(user)
  registerUserChangeListener(
    updateTokenFromUser,
  )
  isServicesInitiated.value = true
}

onMounted(() => {
  initiateServices()
})
</script>

<template>
  <div class="container mt-4">
    <TheHeader :is-online="isOnline" :is-services-initiated="isServicesInitiated" />
    <main>
      <LoggerList
        :is-services-initiated="isServicesInitiated"
        @online-status-update="updateOnlineStatus"
      />
    </main>
    <OfflineOverlay :is-online="isOnline" />
  </div>
</template>
