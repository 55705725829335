<script setup lang="ts">
import type { LoggerType } from '../types'

const props = defineProps<{
  isServicesInitiated: boolean
}>()

const emit = defineEmits<{
  (e: 'onlineStatusUpdate', newOnlineStatus: boolean): void
}>()

const { queryLoggers } = useApiService()

const LOGGER_POLL_INTERVAL = 5000

const FILTER_KEYS = ['name', 'info', 'location', 'mac', 'ip'] as const

const loggersList = ref<LoggerType[]>([])
const filter = ref('')
const isToastTriggered = ref(false)
let pollingTimeoutId: ReturnType<typeof setTimeout> | null = null

const updateLoggersList = (newLoggersList: LoggerType[]) => {
  loggersList.value = newLoggersList
}

const filteredLoggersList = computed(() => {
  return loggersList.value.filter((logger: LoggerType) => {
    if (!filter.value)
      return true

    const isFoundInTags = filter.value.split(',').filter(tag => tag).every((filteredTag) => {
      return logger.tags.some((loggerTag) => {
        return loggerTag.toLowerCase().includes(filteredTag.toLowerCase())
      })
    })

    return isFoundInTags || FILTER_KEYS.some((filterKey) => {
      return logger[filterKey]
        .toLowerCase()
        .includes(filter.value.toLowerCase())
    })
  })
})

const pollLoggers = async () => {
  pollingTimeoutId = setTimeout(pollLoggers, LOGGER_POLL_INTERVAL)

  let queryWentWrong = false

  try {
    const response = await queryLoggers()

    if (response.ok) {
      emit('onlineStatusUpdate', true)
      const res = await response.json()
      updateLoggersList(res)
    }
    else {
      queryWentWrong = true
    }
  }
  catch (error) {
    queryWentWrong = true
    console.error(error)
  }

  if (queryWentWrong) {
    emit('onlineStatusUpdate', false)
    console.error('Couldn’t fetch data (see developer tools console or network tab)')
  }
}

const triggerToast = () => {
  isToastTriggered.value = true
}
const resetToast = () => {
  isToastTriggered.value = false
}

if (props.isServicesInitiated)
  pollLoggers()

watch(() => props.isServicesInitiated, (newValue, oldValue) => {
  if (newValue && !oldValue)
    pollLoggers()
})

onUnmounted(() => {
  if (pollingTimeoutId)
    clearTimeout(pollingTimeoutId)
})
</script>

<template>
  <div>
    <LoggerFilterInput v-model="filter" />
    <div v-if="filteredLoggersList.length > 0" class="list-group">
      <Logger
        v-for="logger in filteredLoggersList" :key="logger.mac" :logger="logger" class="list-group-item last-logger__border"
        @toast="triggerToast()"
      />
    </div>
    <div v-else-if="loggersList.length <= 0" class="text-secondary text-center fw-bold mt-5">
      <i class="list-empty-icon fal fa-empty-set" />
      <div>
        No logger is currently linked to this Discovery Server instance
      </div>
    </div>
    <div v-else class="text-secondary text-center fw-bold mt-5">
      <i class="list-empty-icon fal fa-search" />
      <div>
        No logger matches "{{ filter }}"
      </div>
    </div>
    <ClipboardToast :trigger-toast="isToastTriggered" @reset-toast="resetToast()">
      SSH command copied to clipboard 📋
    </ClipboardToast>
  </div>
</template>

<style scoped lang="sass">
.last-logger__border
  &:last-child
    border-bottom: 1px solid #dee2e6 !important
</style>
