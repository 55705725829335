<script setup lang="ts">
import { formatDistanceToNow, fromUnixTime } from 'date-fns'

const props = defineProps<{
  sshRequestTime: number
}>()
const sessionLength = ref(formatDistanceToNow(fromUnixTime(props.sshRequestTime), { includeSeconds: true }))

const updateSessionLength = () => {
  if (props.sshRequestTime === 0)
    return
  sessionLength.value = formatDistanceToNow(fromUnixTime(props.sshRequestTime), { includeSeconds: true })
}

const updateSessionLengthInterval = setInterval(updateSessionLength, 1000)

onBeforeUnmount(() => {
  clearInterval(updateSessionLengthInterval)
})
</script>

<template>
  <div v-if="props.sshRequestTime > 0">
    <span class="fw-bold">Session has been active for:</span>
    {{ sessionLength }}
  </div>
</template>
