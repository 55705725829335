<script setup lang="ts">
import type { User } from 'oidc-client-ts'

const props = defineProps<{
  isServicesInitiated: boolean
  isOnline: boolean
}>()

const { getUser, unregisterUserChangeListener, registerUserChangeListener, logout } = useAuthentication()

const user = ref<null | User>(null)

const updateUser = (newUser: User) => {
  user.value = newUser
}

const setupUser = async () => {
  user.value = await getUser()
  registerUserChangeListener(updateUser)
}

if (props.isServicesInitiated)
  setupUser()

watch(() => props.isServicesInitiated, async (newValue, oldValue) => {
  if (newValue && !oldValue)
    setupUser()
})

onUnmounted(() => {
  unregisterUserChangeListener(updateUser)
})
</script>

<template>
  <header class="mb-4">
    <div class="d-flex align-items-center">
      <h1>Loggers status</h1>
      <span
        class="badge ms-3 fs-4"
        :class="{ 'bg-success': props.isOnline, 'bg-danger': !props.isOnline }"
      >{{
        isOnline ? "Online" : "Offline"
      }}</span>
    </div>
    <div class="mt-2">
      <div
        v-if="user && user.profile && user.profile.name"
        class="d-flex align-items-center justify-content-end"
      >
        <span>Authenticated as {{ user.profile.name }}</span>
        <button
          class="btn btn-outline-danger btn-sm ms-2"
          @click="logout()"
        >
          Logout
        </button>
      </div>
      <div v-else class="d-flex align-items-center justify-content-end">
        Currently not authenticated
      </div>
    </div>
  </header>
</template>
